var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-2"},[_c('div',{staticClass:"row mx-0"},[(_vm.dataTable.length)?_c('div',{staticClass:"col-12"},[_c('tabla-general',{attrs:{"alto":"calc(100vh - 360px)","data":_vm.dataTable,"activar-seleccion":true},on:{"seleccion":_vm.abrirModal}},[_c('template',{slot:"cabecera-izquierda"},[_c('p',{staticClass:"col-auto py-2 text-general f-18 f-600"},[_vm._v("Lista de retiros aprobados")])]),_c('template',{slot:"adicionales-derecha"},[[_c('el-table-column',{attrs:{"label":"","class-name":"f-16 text-general text-center","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"f-20 text-orange",class:{ 'icon-format-list-checks' : row.estado == 3}})]}}],null,false,558440908)}),_c('el-table-column',{attrs:{"prop":"tipo","label":"Tipo","class-name":"f-16 text-general text-center","width":"125"}}),_c('el-table-column',{attrs:{"prop":"producto_desc","label":"Producto","class-name":"f-16 text-general text-left","width":"500"}}),_c('el-table-column',{attrs:{"label":"Cantidad","class-name":"f-16 text-general text-center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.agregarSeparadoresNumero(row.cantidad))+" ")])]}}],null,false,604575983)}),_c('el-table-column',{attrs:{"label":"Valor","class-name":"f-16 text-general text-center","width":"125"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.separadorNumero(row.total))+" ")])]}}],null,false,3502665966)}),_c('el-table-column',{attrs:{"label":"Fecha Creación","class-name":"f-16 text-general text-center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatearFecha(row.created_at))+" ")])]}}],null,false,1429107865)}),_c('el-table-column',{attrs:{"prop":"leechero","label":_vm.$config.vendedor,"class-name":"f-16 text-general text-left","width":"200"}}),_c('el-table-column',{attrs:{"prop":"cedis_origen","label":"Cedis","class-name":"f-16 text-general text-left","width":"200"}}),_c('el-table-column',{attrs:{"prop":"cedis_destino","label":"Destino","class-name":"f-16 text-general text-left"}})]],2)],2)],1):_c('div',{staticClass:"col-12"},[_c('sin-datos',{attrs:{"icon":"list","mensaje":"No hay datos para mostrar."}})],1)]),_c('modal-informacion-compra',{ref:"modalInformacionCompra"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }